
@import url("//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css");

h4.text {
    line-height: 1.5;
}

#start {    
    background-image: url("/images/bg.jpg") 
}


.test {color: red;}


.footer {
    background: #222222 none repeat scroll 0 0;
    border-top: 4px solid #fcab0e;
    margin-top: 50px;
    padding: 20px 0;
}
.footer_dv {
    width: 100%;
}
.footer_dv h4 {
    color: #fcab0e;
    font-family: roboto;
    font-weight: bold;
    margin-bottom: 30px;
    text-transform: uppercase;
}
.footer_dv ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
.footer_dv ul li:first-child {
    border-top: medium none;
    box-shadow: none;
}
.footer_dv ul li {
    border-top: 1px solid #000;
    box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.2);
    color: #f1f2f2;
    padding: 5px 0;
}
.footer_dv p {
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 15px;
    text-align: justify;
}




// Menu główne
$menu-main-bg: #e9e9e9;

.nav li {border-bottom: 3px solid $menu-main-bg; }

body{
    background: #fff;
}

.navbar, .dropdown-menu{
	background: $menu-main-bg;
	border: none;	
}

.nav>li>a, .dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover, .dropdown-menu>li>a, .dropdown-menu>li{
  border-bottom: 3px solid transparent;
}
.nav>li>a:focus, .nav>li>a:hover,.nav .open>a, .nav .open>a:focus, .nav .open>a:hover, .dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover{
  border-bottom: 3px solid transparent;
  background: none;
}
.navbar a, .dropdown-menu>li>a, .dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover, .navbar-toggle{
 color: #fff;
}
.dropdown-menu{
      -webkit-box-shadow: none;
    box-shadow:none;
}

.nav li:hover:nth-child(8n+1), .nav li.active:nth-child(8n+1){
  border-bottom: #C4E17F 3px solid;
}
.nav li:hover:nth-child(8n+2), .nav li.active:nth-child(8n+2){
  border-bottom: #F7FDCA 3px solid;
}
.nav li:hover:nth-child(8n+3), .nav li.active:nth-child(8n+3){
  border-bottom: #FECF71 3px solid;
}
.nav li:hover:nth-child(8n+4), .nav li.active:nth-child(8n+4){
  border-bottom: #F0776C 3px solid;
}
.nav li:hover:nth-child(8n+5), .nav li.active:nth-child(8n+5){
  border-bottom: #DB9DBE 3px solid;
}
.nav li:hover:nth-child(8n+6), .nav li.active:nth-child(8n+6){
  border-bottom: #C49CDE 3px solid;
}
.nav li:hover:nth-child(8n+7), .nav li.active:nth-child(8n+7){
  border-bottom: #669AE1 3px solid;
}
.nav li:hover:nth-child(8n+8), .nav li.active:nth-child(8n+8){
  border-bottom: #62C2E4 3px solid;
}

.navbar-toggle .icon-bar{
    color: #fff;
    background: #fff;
}






.gold{
  color: #FFBF00;
}

/*********************************************
          PRODUCTS
*********************************************/

.product{
  border: 1px solid #dddddd;
  height: 321px;
}

.product>img{
  max-width: 230px;
}

.product-rating{
  font-size: 20px;
  margin-bottom: 25px;
}

.product-title{
  font-size: 20px;
}

.product-desc{
  font-size: 14px;
}

.product-price{
  font-size: 22px;
}

.product-stock{
  color: #74DF00;
  font-size: 20px;
  margin-top: 10px;
}

.product-info{
    margin-top: 50px;
}


#cart_add_message {
  
  display: none;
}

/*********************************************
          VIEW
*********************************************/

.content-wrapper {
  max-width: 1140px;
  background: #fff;
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: 10px;
  border: 0px;
  border-radius: 0px;
}

.container-fluid{
  max-width: 1140px;
  margin: 0 auto;
}

.view-wrapper {
  float: right;
  max-width: 70%;
  margin-top: 25px;
}

.container {
  padding-left: 0px;
  padding-right: 0px;
  max-width: 100%;
}

/*********************************************
        ITEM 
*********************************************/

.service1-items {
  padding: 0px 0 0px 0;
  float: left;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  height: 321px;
  width: 130px;
}

.service1-item {
  height: 107px;
  width: 120px;
  display: block;
  float: left;
  position: relative;
  padding-right: 20px;
  border-right: 1px solid #DDD;
  border-top: 1px solid #DDD;
  border-bottom: 1px solid #DDD;
}

.service1-item > img {
  max-height: 110px;
  max-width: 110px;
  opacity: 0.6;
  transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -moz-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
}

.service1-item > img:hover {
  cursor: pointer;
  opacity: 1;
}

.service-image-left {
  padding-right: 50px;
}

.service-image-right {
  padding-left: 50px;
}

.service-image-left > center > img,.service-image-right > center > img{
  max-height: 155px;
}

.section_quantity{width:150px;margin-left:-15px;padding:2px;padding-left:15px;padding-right:15px;background:#f8f9f9}
.quantity{width: 150px;}




/*******
 PRODUCT LIST
********/


.glyphicon { margin-right:5px; }

.thumbnail
{
    height: 335px;
    margin-bottom: 20px;
    padding: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}

.item.list-group-item
{
    float: none;
    width: 100%;
    background-color: #fff;
    margin-bottom: 10px;
}
.item.list-group-item:nth-of-type(odd):hover,.item.list-group-item:hover
{
    background: #428bca;
}

.item.list-group-item .list-group-image
{
    margin-right: 10px;
}

.item.list-group-item .thumbnail
{
    margin-bottom: 0px;
}

.item.list-group-item .caption
{
    padding: 9px 9px 0px 9px;
}

.item.list-group-item:nth-of-type(odd)
{
    background: #eeeeee;
}

.item.list-group-item:before, .item.list-group-item:after
{
    display: table;
    content: " ";
}

.item.list-group-item img
{
    float: left;
    width: 253px;
}
.item.list-group-item:after
{
    clear: both;
}
.list-group-item-text
{
    margin: 0 0 11px;
}

.list-group-item {
  
   padding: 5px !important;

}

.list-group-item .thumbnail {
    height: 191px !important;
}


.grid-group-item {
  hight: 200px;
}

.grid-group-item .caption {
  padding: 9px 9px 0px 9px;
}

.grid-group-item .thumbnail img {
  width: 100%;
}


.grid-group-item .list-group-item-text {
  
  height: 50px;
  overflow: hidden;

}

.grid-group-item #card_add {
  margin-bottom: 10px;
}


.grid-group-item h4 {
  height: 40px;
}

.lead {
  
  margin-bottom: 10px !important;
  font-size: 16px;
}

/*** WIDOK KARTY *****/

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  background-color: #eeeeee !important;
  color: #555;
}   


.cart-footer {
  display:inline-block;
}



.panel-borderless {
  border: 0;
  box-shadow: none;
}

